/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {VideoIframe, MapInfo, ThreeColCta} = _components;
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re on this page, you’re probably taking steps to improve your quality of life with some pretty incredible hearing aids. And you’re doing the smart thing by first making sure hear.com is a caring, reputable company. Your hearing is extremely vital to your overall well-being. So you should know you’re in good hands. You’ve definitely come to the right place. We’re so excited you’re here!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Word of mouth is still the best way to learn about a company and its services. And at hear.com, customer satisfaction is our No. 1 priority. We’ve helped more than 100,000 people find their way to better hearing, and enjoy a happier, more fulfilled life — earning us the highest rating with the Better Business Bureau."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most people don’t realize you hear with your brain and not with your ears. And it takes at least 30 days for your brain to adapt to hearing well with hearing aids. Committing to this process is required to guarantee your best outcome. Our Hearing Success Program® was developed to help you succeed and has helped us earn a strong 92% customer success rate."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Each person’s hearing loss is personal; their needs, individual. We’re honored our customers have taken the time to share their stories — and trust their hearing — with us. We invite you to watch some of our customers’ journeys below, and ", React.createElement(_components.a, {
    href: "/hearing-aids/reviews/",
    className: "c-md-a"
  }, "read additional reviews"), " to help you make your important decision."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "trustpilot-testimonials",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#trustpilot-testimonials",
    "aria-label": "trustpilot testimonials permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "TrustPilot Testimonials"), "\n", React.createElement("div", {
    style: {
      marginBottom: '40px'
    },
    className: "js-trustpilot-widget",
    "data-locale": "en-US",
    "data-template-id": "539adbd6dec7e10e686debee",
    "data-businessunit-id": "5601900f0000ff00058398fb",
    "data-style-height": "1000px",
    "data-style-width": "100%",
    "data-theme": "light",
    "data-stars": "4,5",
    "data-review-languages": "en"
  }, React.createElement("a", {
    href: "https://www.trustpilot.com/review/hear.com",
    target: "_blank",
    rel: "noopener"
  }, "Trustpilot")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "video-testimonials",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#video-testimonials",
    "aria-label": "video testimonials permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Video Testimonials"), "\n", React.createElement(VideoIframe, {
    title: "Testimonials from hear.com Customers",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/videoseries?list=PLajAOs3CnoqmV-W571zIVinA-zVlWCWXM"
  }), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "There are several types of hearing loss and many different cause.",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
